<template>
  <div class="user-wrapper">
    <div class="container">
        <a-row style="margin:24px 31px;font-size: 22px;font-weight: 500;color: #333333;">
            <a-col :span="24"><span>订单信息</span></a-col>
        </a-row>
        <div class="order_info">
            <a-row class="order_head">
                <a-col :span="6"><span>下单时间: {{orderInfo.create_at}}</span></a-col>
                <a-col :span="12" style="text-align:left;"><span>订单编号:{{orderInfo.order_no}}</span></a-col>
                <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==0">
                  <img src="@/assets/images/web/music/order_payment.svg" style="width:18px;" />
                  <span style="color: #FF7B13;">    待支付    </span>
                </a-col>
                <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==2||orderInfo.status==1">
                  <img src="@/assets/images/web/music/order_cancel.svg" style="width:18px;" />
                  <span style="color: #999999;">    已取消    </span>
                </a-col>
                 <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==3">
                  <img src="@/assets/images/web/music/order_paying.svg" style="width:18px;" />
                  <span style="color: #FF7B13;">    支付中    </span>
                </a-col>
                 <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==4">
                  <img src="@/assets/images/web/music/order_done.svg" style="width:18px;" />
                  <span style="color:#46B1A7;">    已完成    </span>
                </a-col>
            </a-row>
            <a-row class="desc">
                <a-col :span="10">
                    <a-col :md="6" :xs="12" style="line-height:36px;padding-left:24px;">
                    <a-col :span="24">总价：  </a-col>
                    <a-col :span="24">优惠券：</a-col>
                    <a-col :span="24">实付：  </a-col>
                </a-col>
                <a-col :md="18" xs="12"  style="text-align:left;line-height:36px;">
                    <a-col :span="24">¥ {{orderInfo.total_amount}}</a-col>
                    <a-col :span="24">¥ {{orderInfo.discount_amount}}</a-col>
                    <a-col :span="24">¥ {{orderInfo.pay_amount}}</a-col>
                </a-col>
                </a-col>
                <a-col :span="14" style="line-height:28px;padding-left:20%;text-align:center;font-weight:500;">
                    <a-col :span="24"><span style="color: #999999;">请向客服提交订单号和付款凭证截图，我们会帮助完成订单</span></a-col>
                    <a-col :span="24"><span>账户名称</span><span>北京火星火翎文化科技有限公司</span></a-col>
                    <a-col :span="24"><span>银行账户</span><span>100010124824</span></a-col>
                    <a-col :span="24"><span>开户行</span><span>友利银行（中国）有限公司</span></a-col>
                </a-col>
            </a-row>
            <a-row class="order_bottom">
                <a-col :span="24"><span>商品信息</span></a-col>
            </a-row>
            <a-row class="bottom_desc">
                <a-col v-for="(cItem, key) in orderInfo.detail" :key="key" :span="24" style="height:114px;">
                    <a-col :span="4"><img :src="cItem.logo_url" style="width:90px;height:90px;border-radius: 2px;"/></a-col>
                    <a-col :span="6" style="line-height:30px;">
                        <a-col :span="24" class="ellipsis"><span style="font-weight: 600;color: #333333;font-size: 16px;">{{cItem.music_name}}</span></a-col>
                        <a-col :span="24" class="ellipsis">{{cItem.suplr_name}}</a-col>
                    </a-col>
                    <a-col :span="8">
                        <a-col :span="24">
                            <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelUseMethod')}}:</a-col>
                            <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.use_mode}}</a-col>
                            </a-col>
                            <a-col :span="24">
                            <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelUseScene')}}:</a-col>
                            <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_scene | toAuthScene}}</a-col>
                            </a-col>
                            <a-col :span="24">
                            <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelAuthArea')}}:</a-col>
                            <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_area | toArea}}</a-col>
                            </a-col>
                            <a-col :span="24">
                            <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelAuthTime')}}:</a-col>
                            <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_time | toAuthPeriod}}</a-col>
                        </a-col>
                    </a-col>
                    <a-col :span="6" style="line-height:90px;"><span style="font-weight: 400;color: #E30D0D;font-size:16px;">¥ {{cItem.price}}</span></a-col>
                </a-col>
            </a-row>
        </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        orderInfo: {},
        order_id: ''
      }
    },
    created() {
      let order_id = this.$route.params.order_id || '';
      this.order_id = order_id;
      this.getOrderDetail({order_id})
    },

    components: {
    },
    methods: {
      getOrderDetail(params) {
        this.$axios.OrderInfo(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.orderInfo = data.data || {};
          }
        })
      },
    },

  }
</script>

<style lang="scss" scoped>
    .user-wrapper {
        width: 100%;
        padding-top: 24px;
        background: #F9FAFA;
        vertical-align:top;
    }
    .order_info{
        min-height: 729px;
        background: #FFFFFF;
        width: 100%;
        padding-top: 20px;
        padding: 21px 31px;
        .desc{
            height: 170px;
            border: 1px solid #EBF0F2;
            padding-top:24px;
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .order_head, .order_bottom{
        width:100%;
        background: #F8FAFB;
        border: 1px solid #EBF0F2;
        line-height: 46px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding: 0 24px;
    }
    .order_bottom{
        margin-top: 24px;
    }
    .bottom_desc{
        border: 1px solid #EBF0F2;
        padding: 24px;
    }
</style>
